// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-atoms-article-card-js": () => import("./../src/pages/components/atoms/ArticleCard.js" /* webpackChunkName: "component---src-pages-components-atoms-article-card-js" */),
  "component---src-pages-components-atoms-button-js": () => import("./../src/pages/components/atoms/Button.js" /* webpackChunkName: "component---src-pages-components-atoms-button-js" */),
  "component---src-pages-components-atoms-button-original-js": () => import("./../src/pages/components/atoms/Button_original.js" /* webpackChunkName: "component---src-pages-components-atoms-button-original-js" */),
  "component---src-pages-components-atoms-pankuzu-js": () => import("./../src/pages/components/atoms/Pankuzu.js" /* webpackChunkName: "component---src-pages-components-atoms-pankuzu-js" */),
  "component---src-pages-components-atoms-side-blog-list-js": () => import("./../src/pages/components/atoms/SideBlogList.js" /* webpackChunkName: "component---src-pages-components-atoms-side-blog-list-js" */),
  "component---src-pages-components-atoms-toc-js": () => import("./../src/pages/components/atoms/Toc.js" /* webpackChunkName: "component---src-pages-components-atoms-toc-js" */),
  "component---src-pages-components-footer-jsx": () => import("./../src/pages/components/Footer.jsx" /* webpackChunkName: "component---src-pages-components-footer-jsx" */),
  "component---src-pages-components-header-jsx": () => import("./../src/pages/components/Header.jsx" /* webpackChunkName: "component---src-pages-components-header-jsx" */),
  "component---src-pages-components-image-js": () => import("./../src/pages/components/image.js" /* webpackChunkName: "component---src-pages-components-image-js" */),
  "component---src-pages-components-layout-jsx": () => import("./../src/pages/components/Layout.jsx" /* webpackChunkName: "component---src-pages-components-layout-jsx" */),
  "component---src-pages-components-main-jsx": () => import("./../src/pages/components/Main.jsx" /* webpackChunkName: "component---src-pages-components-main-jsx" */),
  "component---src-pages-components-molecules-article-list-js": () => import("./../src/pages/components/molecules/ArticleList.js" /* webpackChunkName: "component---src-pages-components-molecules-article-list-js" */),
  "component---src-pages-components-molecules-nav-bar-js": () => import("./../src/pages/components/molecules/NavBar.js" /* webpackChunkName: "component---src-pages-components-molecules-nav-bar-js" */),
  "component---src-pages-components-molecules-sub-nav-bar-2-js": () => import("./../src/pages/components/molecules/SubNavBar 2.js" /* webpackChunkName: "component---src-pages-components-molecules-sub-nav-bar-2-js" */),
  "component---src-pages-components-molecules-sub-nav-bar-js": () => import("./../src/pages/components/molecules/SubNavBar.js" /* webpackChunkName: "component---src-pages-components-molecules-sub-nav-bar-js" */),
  "component---src-pages-components-molecules-tag-bar-js": () => import("./../src/pages/components/molecules/TagBar.js" /* webpackChunkName: "component---src-pages-components-molecules-tag-bar-js" */),
  "component---src-pages-components-seo-js": () => import("./../src/pages/components/seo.js" /* webpackChunkName: "component---src-pages-components-seo-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-profile-2-jsx": () => import("./../src/pages/profile 2.jsx" /* webpackChunkName: "component---src-pages-profile-2-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-sample-page-jsx": () => import("./../src/pages/sample-page.jsx" /* webpackChunkName: "component---src-pages-sample-page-jsx" */),
  "component---src-pages-templates-blog-post-jsx": () => import("./../src/pages/templates/blog-post.jsx" /* webpackChunkName: "component---src-pages-templates-blog-post-jsx" */),
  "component---src-pages-templates-categories-jsx": () => import("./../src/pages/templates/categories.jsx" /* webpackChunkName: "component---src-pages-templates-categories-jsx" */),
  "component---src-pages-templates-tags-jsx": () => import("./../src/pages/templates/tags.jsx" /* webpackChunkName: "component---src-pages-templates-tags-jsx" */)
}

